import { useFrame } from '@react-three/fiber'
import { useRef } from 'react'
import * as THREE from 'three'
import vertex from './vertex.glsl'
import fragment from './fragment.glsl'

export default function Shader()
{
    const overlayMaterial = new THREE.ShaderMaterial({
        uniforms: 
        {
            u_alpha: { value: 1.0},
            u_time: { value: 0.},
            u_resolution: { type: "v2", value: new THREE.Vector2() },
            u_mouse: { type: "v2", value: new THREE.Vector2() },
        },
        vertexShader: vertex,
        fragmentShader: fragment
    })
    const DPR = Math.min(window.devicePixelRatio, 1.);
    const overlayGeometry = new THREE.PlaneGeometry(2 * DPR, 2 * DPR, 1, 1)
    const meshRef = useRef()
    let mouseX;
    let mouseY;

    useFrame(({clock}) => {
        meshRef.current.material.uniforms.u_time.value = clock.elapsedTime
        meshRef.current.material.uniforms.u_mouse.value = new THREE.Vector2(mouseX, mouseY)
        meshRef.current.material.uniforms.u_resolution.value = new THREE.Vector2(
            window.innerWidth * DPR,
            window.innerHeight * DPR
        )
    })

    addEventListener('mousemove', (e) => {
        mouseX = (mouseX, (e.clientX / window.innerWidth)) ;
        mouseY = (mouseY, (-(e.clientY / window.innerHeight) + 1)) ;
    })

    return(
        <>
            <mesh ref={meshRef}
                material={overlayMaterial}
                geometry={overlayGeometry}
            />
        </>
    )
}