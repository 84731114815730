import LogoFile from "../../public/Logo/LogoFileWhite";

export default function Logo()
{

    const logoStyle = {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        // scale: '50%',
        // width: '100%',
        transform: 'translate(-50%, -50%)'
    }

    const headerStyle = {
        color: 'white',
        fontFamily: 'InterLight',
        position: 'relative',
        left: '45%',
        top: '50%',
        fontSize: '3em',
        width: '100%',
    }

    return (
        <>
            <div style={logoStyle} >
                <LogoFile className="logo" />
                {/* <h1 style={headerStyle}>coming soon...</h1> */}
            </div>
            
        </>
    )
    
}