import { Canvas } from "@react-three/fiber"
import { OrbitControls } from "@react-three/drei"
import Logo from "./Logo"
import Shader from "./Shader/Shader.jsx"

export default function Experience()
{
    return <>
        <Logo />
        <Canvas shadows camera={{fov: 50, position: [0, 1.5, 5]}} >
            <Shader />
        </Canvas>
        
    </>
}