varying vec2 vUv;
uniform float u_time;
uniform vec2 u_mouse;
uniform vec2 u_resolution;

float sdCircle(vec2 p, float r)
{
    return length(p) - r;
}

void main()
{
    vec2 vUv = vec2(vUv.x, vUv.y) * u_resolution;

    vec3 color;
    vec2 m = u_mouse.xy;

    vec2 offset = vec2(m) * u_resolution;
    vec3 col = vec3(0.25, 0.25, 0.25);
    float d =  sdCircle(vUv - offset, 0.21);
    float d1 = sdCircle(vUv - offset, 250.31);
    float fin = smoothstep(d, d1, 0.01);
    color += (step(0., -fin)) * col;

    float glow = 0.005/ fin;
    glow = clamp(glow, 0., 1.);
    glow = glow * 15. * (sin(u_time)/10. + 0.75);

    color += col * glow ;
    gl_FragColor = vec4(color, 1.);
}